<template>
  <div>
    <h5>
      แผนผังการไหลของพลังงานของภาพฉายอนาคตด้านพลังงานในกรณี
      {{ scenarioText }} RE
    </h5>
    <div id="sankey-container"></div>
    <div>
      <a class="btn btn-red py-1 px-4 mr-3" href="javascript:history.go(-1)">
        <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
        ย้อนกลับ (Scenario: {{ $route.params.type }})
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'scenarios.scenario-detail',
  mounted() {
    // console.log(this.$route.params.scenario);
    this.fetchGraph();
  },
  computed: {
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince']),
    scenarioText() {
      switch (this.$route.params.scenario) {
        case 'P001':
        case 'P007':
        case 'P013':
          return 'Min Cost.';
        case 'P002':
        case 'P008':
        case 'P014':
          return '20%';
        case 'P003':
        case 'P009':
        case 'P015':
          return '40%';
        case 'P004':
        case 'P010':
        case 'P016':
          return '60%';
        case 'P005':
        case 'P011':
        case 'P017':
          return '80%';
        case 'P006':
        case 'P012':
        case 'P018':
          return '100%';

        default:
          return '';
      }
    },
  },
  methods: {
    ...mapActions('mondas', ['fetchScenarioSankey']),
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    },
    fetchGraph() {
      // console.log('fetch');
      this.fetchScenarioSankey({
        province: this.currentProvince,
        scenario: this.$route.params.scenario,
      }).then((graph1) => {
        // console.log(graph1);
        // console.log('done fetch');
        if (graph1) {
          let sankeySvgContainer = document.getElementById('sankey-container');
          // graph1 = this.replaceAll(graph1, 'font-size="10"', 'font-size="24"');
          // graph1 = this.replaceAll(graph1, 'font-size="9"', 'font-size="22"');
          sankeySvgContainer.innerHTML = graph1;
        }
      });
    },
  },
};
</script>

<style scoped></style>
